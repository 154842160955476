.ry0o8xu{display:grid;gap:1rem;-webkit-align-content:center;-ms-flex-line-pack:center;align-content:center;grid-template-columns:repeat(2,1fr);-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.a1daog6t{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.c1eir22k{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-flex-basis:40%;-ms-flex-preferred-size:40%;flex-basis:40%;text-align:var(--c1eir22k-0);}
.sfkzlav.s93dlux{margin-top:1.5rem;max-width:39.75rem;}
.syt9il0.s93dlux{margin-top:0.5rem;}
.s2jr36s.t17lg09t{font-weight:var(--s2jr36s-0);font-size:var(--s2jr36s-1);}
.s1meiexs.t17lg09t{font-weight:600;font-size:1.5rem;}
.b19o1o0p{background:var(--b19o1o0p-0);}.b19o1o0p:hover{background:var(--b19o1o0p-1) !important;}
.msfuzr7{max-width:39.75rem;}
.t9rkyvf{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.t9rkyvf .title{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1rem;font-size:1.5rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.t9rkyvf .close{position:absolute;right:1.5rem;cursor:pointer;}
.hydn1pc{font-size:0.875rem;-webkit-text-decoration:none;text-decoration:none;color:#717171;}
.p19ulll7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0.125rem;}
.s1kz0712{color:#0CA759;}
.b18qldoe{max-width:15rem;}
.mezhqx6{position:absolute;top:2.5rem;}
